@font-face {
  font-family: "circular";
  src: url("https://cerclex-public-static-content.s3.ap-south-1.amazonaws.com/CircularStd-Book.otf");
}

/*! To be added to global css */

.input-field-style {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  padding: 10px 10px;
  height: 54px;
  background-color: #fff;
  border: 1px solid #dadae7;
  margin-top: 1rem;
}

.input-field-style > input {
  width: 100%;
  border: none;
  outline: none;
}

.phone-input-style {
  border: 1px solid #dadae7 !important;
  background-color: #fff !important;
  height: 54px !important;
  width: 100% !important;
  border-radius: 6px !important;
  padding-left: 12% !important;
  font-size: 1.2rem !important;
}

.phone-input-button-style {
  background: #fff !important;
  border: 1px solid #dadae7 !important;
  border-radius: 6px 0 0 6px !important;
  width: 10%;
  height: 54px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.underline {
  text-decoration: underline;
}

ml-half {
  margin-left: 0.5rem;
}

.pointer{
  cursor: pointer;
}

/*! ---To be added to global css ends here--*/

* {
  padding: 0;
  margin: 0;
  box-sizing: 0;
  font-family: "circular";
}

a {
  color: #000;
}

.account-setting-dropdown {
  width: 14vw !important;
  height: 10vh;
}

.search-tab {
  background-color: #ffff;
  display: flex;
  border-radius: 5px;
  align-items: center;
  width: 25vw;
  border: 1px solid #ececec;
  padding: 0 7px;
}

.search-tab > input {
  height: 1.8rem;
  border-left: 1px solid #ececec !important;
  padding-left: 4px;
}

.auth-page {
  /* background-image: url('../public/images/auth-screen.png'); */
  background-repeat: no-repeat;
  
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-btn {
  background-color: antiquewhite;
  padding: 5px 10px;
  border-radius: 5px;
  color: #6f2da8;
  background-color: #ece0f4;
}

.secondary-btn:hover {
  cursor: pointer;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-info {
  margin-top: -6rem;
}

.auth-from {
  min-width: 50vw;
  padding: 0 6rem;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-direction: column;
}

.input-field {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  padding: 3px 10px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-top: 5px !important;
}

.primary-btn {
  border: none;
  padding: 10px 30px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
}

.input-field > input {
  width: 100%;
  border: none;
  outline: none;
}

.error-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  height: 100vh;
}

.header {
  height: 7.5vh;
  position: fixed;
  z-index: 2;
  box-shadow: 2px 2px 12px #ddd;
}

.error-image {
  width: 30vw;
  height: 40vh;
}

.bold {
  font-size: 1.2rem;
  font-weight: 700;
}

.access-denied-page {
  background-image: url("../public/images/access-denied.png");
  height: 100%;
  width: 100vw;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.sadAvatarImage {
  width: 20vw;
  height: 40vh;
}

.app-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  resize: initial;
}

.app-box {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #c5ccda;
  padding: 8px 25px;
  gap: 10px;
  min-width: 150px;
}

.loading-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(229, 229, 229, 0.401);
  position: absolute;
  z-index: 2;
}

.earth-image {
  width: 450px;
}

.menu {
  background-color: #fff;
  height: 100vh !important;
  width: 100vw !important;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  padding: 20px;
  padding-right: 30px;
}

input[type="tel"]:not(.browser-default):focus:not([readonly]) {
  border-color: #fff !important;
}

@media screen and (max-width: 1700px) {
  .earth-image {
    width: 350px;
  }
}

@media screen and (max-width: 1050px) {
  .auth-form {
    width: 100%;
  }

  .error-image {
    width: 80%;
    height: 35vh;
  }

  .sadAvatarImage {
    width: 50%;
  }

  .access-denied-page {
    padding: 10px;
  }
}

@media screen and (max-width: 850px) {
  .app-box {
    width: 80vw;
  }

  .auth-from {
    min-width: 100vw;
    padding: 0 1.4rem;
  }
}

@media screen and (max-width: 650px) {
  .auth-page {
    background-image: none;
    /* background-color: #fff; */
  }
}
